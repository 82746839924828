import React from "react";
import Bubu from ".././components/img/Echipa/Bubu.jpg";
import Andrei from ".././components/img/Echipa/Andrei.jpg";
import Cristi from ".././components/img/Echipa/Cristi.png";
import Bogdan from ".././components/img/Echipa/Bogdan.jpg";
import "../css/App.css";
import "./css/Echipa.css";

const Echipa = () => {
  return (
    <>
      <h1 className="page-name">Echipă</h1>
      <div className="team-container">
        <div className="photo-container">
          <div className="image-container">
            <img
              src={Bubu}
              alt={`Mihai "Bubu" Cernea, tobosar, inginer de sunet, compozitor, tehnic`}
            />
          </div>
          <div className="text-container left">
            <h1>Mihai "Bubu" Cernea</h1>
            <p>
              Ca și instrumentist, Mihai “Bubu” Cernea nu mai are nevoie de
              nicio prezentare. Multă lume știe că este unul din cei mai buni
              toboșari din Europa, însă ceea ce puțini știu este că Bubu este un
              compozitor și un producător desăvârșit.
            </p>
            <p>
              Studiul pianului in copilărie l-a facut să aibă o înțelegere
              profundă asupra armoniei și a muzicii în general, iar orele
              petrecute în studiourile de la Berklee College of Music,
              facultatea pe care a absolvit-o în US, l-au făcut să înțeleagă tot
              procesul din spatele imprimării unei idei muzicale.
            </p>
            <p>
              Bubu consideră că atât anturajul muzical cât și studioul în care
              un artist își petrece timpul au un impact puternic asupra muzicii
              sale, așa că trebuie ales cu grijă.
            </p>
          </div>
        </div>
        <div className="photo-container">
          <div className="image-container">
            <img
              src={Cristi}
              alt="Cristian Comaroni, inginer de sunet, chitarist, compozitor"
            />
          </div>
          <div className="text-container right">
            <h1>Cristian Comaroni</h1>
            <p>
              Studiind chitara de la o vârstă fragedă, Cristian a descoperit că
              în spatele fiecărei piese există o poveste. De aceea, la scurt
              timp după ce a învățat conceptele muzicale de bază, a început să
              le folosească pentru a-și creiona primele compoziții.
            </p>
            <p>
              Motivat de dorința de a-și înregistra creațiile, a învățat cât mai
              multe despre tehnologia de studio, perfecționându-se în mai bine
              de un deceniu de practică. Așadar, decizia de a înființa un studio
              împreună cu colegii săi de scenă a fost una cât se poate de
              organică.
            </p>
            <p>
              Cristian consideră că atenția sa sporită la detalii funcționează
              foarte bine în tandem cu abilitățile celorlalți membri ai echipei
              InfraRED: “Cred că în timpul procesului de perfecționare al
              sunetului, unul din rolurile mele este de a avea grijă ca povestea
              din spatele muzicii sa rămână autentică.”
            </p>
          </div>
        </div>
        <div className="photo-container">
          <div className="image-container">
            <img
              src={Andrei}
              alt="Andrei Comaroni, producator, chitarist, compozitor"
            />
          </div>
          <div className="text-container left">
            <h1>Andrei Comaroni</h1>
            <p>
              Unul din cei mai promițători producători ai generației sale,
              Andrei și-a început cariera de chitarist la vârsta de 7 ani,
              urmând să treacă prin mai multe proiecte muzicale înainte de a
              ajunge în proiectul Irinei Rimes.
            </p>
            <p>
              Dorința lui permanentă de a învăța cum funcționează lucrurile l-a
              îndemnat să urmeze cursurile Politehnicii de Electronică pentru a
              înțelege relația intimă dintre muzică și computer în era digitală.
            </p>
            <p>
              Aprofundând acest subiect, Andrei a reușit să își programeze
              propriul sintetizator digital printr-o muncă incredibil de
              dificilă si de minuțioasă. Urechile sale de instrumentist îl ajută
              să folosească uneltele digitale pentru producție și sound design
            </p>
          </div>
        </div>
        <div className="photo-container">
          <div className="image-container">
            <img src={Bogdan} alt="Bogdan Bucătaru, sunetist profesionist" />
          </div>
          <div className="text-container right">
            <h1>Bogdan Bucătaru</h1>
            <p>
              Înca de la începuturile carierei sale Bogdan Bucătaru a înteles că
              nu poate evolua stând pe loc. De aceea a urmărit toate
              oportunitățile care i-au ieșit în cale, lucrând în numeroase
              proiecte de mainstream și underground.
            </p>
            <p>
              Dupa ce a mixat sute, poate chiar mii de concerte live atât în
              țară cât și în străinătate, Bogdan a decis să răspundă chemării
              studioului. Astfel, a petrecut mai bine de jumătate de deceniu
              învățând echipamentele și softurile de studio precum și conceptele
              din spatele lor, ajungând să fie unul din cei mai căutați ingineri
              de sunet din România.
            </p>
            <p>
              Bogdan este îndrăgostit de vocea umană, susținând că ea este
              aproape întotdeauna cel mai important element al unui mix, dar și
              cel mai fascinant: “cu un EQ și o voce înregistrată corect,
              posibilitățile de caracter sunt nelimitate”
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Echipa;
