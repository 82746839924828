import React from "react";
import "./css/About.css";

export default function About() {
  return (
    <div className="main-container">
      <div
        className="main-title-master"
        title="Poza reprezentativa, membrii de baza ai studioului InfraRED"
      >
        <div className="main-title">
          <h1 className="main-title-despre">Despre</h1>
          <h1 className="main-title-noi">noi</h1>
        </div>
      </div>
      <p className="main-paragraph">
        Echipa noastră a evoluat în prezența multor artiști incredibili, astfel
        căpătând experiență valoroasă în multe genuri muzicale, medii artistice
        și domenii alăturate muzicii.
      </p>
      <p className="main-paragraph">
        Întotdeauna am abordat provocările cu seriozitate, entuziasm și
        creativitate, construindu-ne o reputație care ne-a ajutat să facem parte
        în prezent din proiecte extraordinare precum Alina Eremia, Irina Rimes,
        Inna și Damian Drăghici.
      </p>
      <p className="main-paragraph">
        Rolurile pe care ni le-am asumat în aceste proiecte nu au fost doar cele
        de instrumentiști, deoarece, cu timpul, am atras în jurul nostru oameni
        cu aceeași viziune și dorință permanentă de dezvoltare.
      </p>
      <p className="main-paragraph">
        Împreună am orchestrat și produs showurile live ale Alinei și Irinei,
        oferindu-le un sound de band modern care rezonează cu personalitățile
        lor. Suntem extrem de recunoscători că putem ajuta acești muzicieni
        talentați să-și expună mesajul în cea mai bună formă a sa, atât pe
        scenă, cât și în afara ei.
      </p>
      <p className="main-paragraph">
        În același timp, am folosit oportunitatea de a orchestra și aranja
        showurile a două soliste excepționale pentru a evolua ca muzicieni și
        compozitori, dar și pentru a ne perfecționa în utilizarea echipamentelor
        de studio.
      </p>
      <p className="main-paragraph">
        Așadar, următorul pas natural pentru noi a fost întemeierea{" "}
        <b>InfraRED Studios</b>, pentru a îmbina experiența de “live” cu cea de
        studio, sperând astfel să promovăm cât mai mult muzica de calitate, să
        susținem tinere talente și să dăm viață muzicii tale.
      </p>
      <h1 className="main-paragraph-title">Misiune și viziune</h1>
      <p className="main-paragraph">
        Misiunea noastră este de a forma un loc în care cei mai talentați
        artiști, songwriteri, instrumentiști și producători pot colabora.
      </p>
      <p className="main-paragraph">
        Considerăm că piața muzicală româneasca este departe de a fi saturată și
        există loc pentru oricine este suficient de pasionat și curajos, însă
        tinerele talente nu sunt întotdeauna sprijinite suficient.
      </p>
      <p className="main-paragraph">
        Astăzi, în Romania, există prea mulți artiști talentați pe care nu îi
        aude nimeni. Dacă Michael Jackson nu ar fi ajuns pe scenă la
        începuturile carierei sale și s-ar fi lăsat de muzică, cu siguranță
        lumea nu ar arăta la fel.
      </p>
      <p className="main-paragraph">
        Știm că de cele mai multe ori, artiștii cu potențial renunță la pasiunea
        lor pentru că nu reușesc să facă “saltul” către scenă. In viziunea
        noastră, a avea talentul și dedicarea pentru a putea ajunge un artist
        excepțional, dar a nu avea acces la uneltele profesionale cu care să-ți
        începi cariera este o pierdere imensă.
      </p>
      <p className="main-paragraph">
        Credem că rolul de educator, formator de caracter și terapeut al muzicii
        nu trebuie lăsat să dispară. Mare parte din misiunea noastră este de a
        găsi noi talente cu mesaj sincer, de a le educa în privința
        responsabilității lor față de societate și de a le face loc pe piața
        muzicală.
      </p>
      <p className="main-paragraph">
        Echipa <b>InfraRED</b> este formată exclusiv din muzicieni care cunosc
        industria muzicală și toate etapele necesare pe care un artist trebuie
        să le parcurgă pentru a se face auzit; tocmai de aceea, ne asumăm
        responsabilitatea de a ajuta artiștii independenți să concureze cu cei
        din mainstream la cel mai înalt nivel.
      </p>
    </div>
  );
}
