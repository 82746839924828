import React, { useEffect } from "react";
import "./css/App.css";
import Nav from "./components/Nav";
import Main from "./components/Main";
import Footer from "./components/Footer";
import About from "./pages/About";
import Echipa from "./pages/Echipa";
import Echipamente from "./pages/Echipamente";
import Proiecte from "./pages/Proiecte";
import Media from "./pages/Media";
import ErrorPage from "./Error";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ScrollToTop from "./components/Scrolltotop";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <div className="content">
          <Nav />
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/desprenoi" component={About} />
            <Route path="/proiecte" component={Proiecte} />
            <Route path="/echipamente" component={Echipamente} />
            <Route path="/media" component={Media} />
            <Route path="/echipa" component={Echipa} />
            <Route path="/error" component={ErrorPage} />
            <Route render={() => <Redirect to="/error" />} />
          </Switch>
        </div>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
