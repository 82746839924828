import React, { useState, useRef } from "react";
import "./css/Nav.css";
import "./css/NavImg.css";
import { Link } from "react-router-dom";
import logoAlb from "./img/Logo/INFRARED LOGO - alb.png";

export default function Nav() {
  const [burgerState, setBurgerState] = useState(false);
  const isScrolled = false;

  const toggleState = () => {
    setBurgerState((prev) => !prev);
  };

  const navSvgAnimationRef = useRef();
  const animateNavSvg = () => {
    navSvgAnimationRef.current.classList.add("animated");
  };
  const deanimateNavSvg = () => {
    navSvgAnimationRef.current.classList.remove("animated");
  };

  return (
    <div className={`nav-container ${isScrolled ? "scrolled" : ""}`}>
      <div className={`nav-image ${isScrolled ? "scrolled" : ""}`}>
        <Link to="/">
          <img
            className="nav-image phone"
            src={logoAlb}
            alt="InfraRED Studios Logo"
          />
          <svg
            width="250"
            height="140"
            viewBox="0 0 1403 1080"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="pc"
            ref={navSvgAnimationRef}
            onMouseOver={animateNavSvg}
            onAnimationEnd={deanimateNavSvg}
            alt="InfraRED Studios Animated Logo"
          >
            <g id="InfraRED Logo Black 1">
              <g id="infrared">
                <path
                  id="Vector"
                  d="M235.78 603.15V504.8H248.39V603.15H235.78Z"
                  fill="black"
                />
                <path
                  id="Vector_2"
                  d="M271.15 527.42H283.47V537.42H283.84C286.65 532.59 293.64 526.18 305.96 526.18C321.52 526.18 333.12 536.12 333.12 554.57V603.13H320.73V556.68C320.73 543.92 313.23 537.44 302.21 537.44C289.21 537.44 283.55 546.66 283.55 557.04V603.15H271.15V527.42Z"
                  fill="black"
                />
                <path
                  id="Vector_3"
                  d="M358.76 537.8H348.53V527.42H358.76V519.86C358.76 505.38 366.03 498.68 381.6 498.68C383.456 498.662 385.309 498.806 387.14 499.11V509.27C385.854 509.067 384.552 508.97 383.25 508.98C375.04 508.98 371.08 511.64 371.08 520.87V527.42H386V537.8H371.15V603.15H358.76V537.8Z"
                  fill="black"
                />
                <path
                  id="Vector_4"
                  d="M404.86 527.42H417.25V536.72H417.62C419.85 532.18 425.54 526.27 436.28 526.27C437.847 526.278 439.411 526.398 440.96 526.63V537.94C438.968 537.719 436.964 537.626 434.96 537.66C421.56 537.66 417.38 546.01 417.38 556.24V603.15H404.86V527.42Z"
                  fill="black"
                />
                <path
                  id="Vector_5"
                  d="M475.11 604.38C460.48 604.38 450.39 595.3 450.39 581.38C450.39 568.12 459.62 559.77 476.98 559.69H496.98V552C496.98 542.06 491.21 536.58 480.98 536.58C471.26 536.58 465.71 541.58 464.98 549.12H453.27C454.07 535.86 463.07 526.21 481.38 526.21C497.51 526.21 509.26 535 509.26 550.92V603.16H497.37V593.72H497C493.19 599.84 486.27 604.38 475.11 604.38ZM496.94 578V569.21H477.94C467.56 569.21 463.09 574.11 463.09 580.96C463.09 589.89 470.52 594.07 478.44 594.07C489 594.07 496.94 587.44 496.94 578Z"
                  fill="black"
                />
              </g>
              <g id="studios">
                <path
                  id="Vector_6"
                  d="M720 668.21C719.969 666.935 720.253 665.671 720.824 664.531C721.396 663.391 722.24 662.408 723.28 661.67C725.467 660.017 728.393 659.193 732.06 659.2C735.13 659.05 738.162 659.934 740.67 661.71C741.712 662.479 742.557 663.482 743.138 664.64C743.718 665.797 744.017 667.075 744.01 668.37H736.83C736.807 667.84 736.668 667.322 736.422 666.852C736.177 666.382 735.831 665.972 735.41 665.65C734.406 665.034 733.257 664.696 732.079 664.671C730.901 664.647 729.739 664.936 728.71 665.51C728.335 665.745 728.026 666.073 727.814 666.462C727.602 666.85 727.494 667.287 727.5 667.73C727.5 669.123 728.743 670.123 731.23 670.73L736.7 671.91C738.967 672.272 741.097 673.231 742.87 674.69C743.511 675.349 744.009 676.133 744.332 676.994C744.656 677.854 744.798 678.772 744.75 679.69C744.775 681.024 744.466 682.343 743.852 683.527C743.237 684.711 742.336 685.723 741.23 686.47C738.503 688.279 735.268 689.165 732 689C728.233 689 725.233 688.163 723 686.49C721.914 685.716 721.016 684.707 720.374 683.539C719.732 682.37 719.361 681.072 719.29 679.74H726.9C726.969 680.296 727.152 680.831 727.438 681.313C727.724 681.795 728.106 682.213 728.56 682.54C729.657 683.254 730.952 683.604 732.26 683.54C733.473 683.603 734.679 683.322 735.74 682.73C736.129 682.503 736.45 682.177 736.672 681.785C736.893 681.394 737.006 680.95 737 680.5C737.013 680.166 736.948 679.834 736.809 679.53C736.67 679.226 736.461 678.958 736.2 678.75C735.381 678.195 734.454 677.82 733.48 677.65L728.2 676.47C722.7 675.237 719.967 672.483 720 668.21Z"
                  fill="black"
                />
                <path
                  id="Vector_7"
                  d="M750.65 653.23H758.44V659.81H763.72V665.68H758.44V679.36C758.44 681.513 759.56 682.59 761.8 682.59C762.432 682.599 763.065 682.555 763.69 682.46V688.19C762.472 688.431 761.231 688.539 759.99 688.51C756.657 688.51 754.26 687.907 752.8 686.7C751.34 685.493 750.623 683.493 750.65 680.7V665.7H746.65V659.83H750.65V653.23Z"
                  fill="black"
                />
                <path
                  id="Vector_8"
                  d="M794.39 659.81V688.4H786.87V683.26H786.71C785.217 687.04 782.363 688.933 778.15 688.94C776.769 689.006 775.39 688.785 774.099 688.291C772.808 687.797 771.634 687.041 770.65 686.07C769.687 685.016 768.943 683.781 768.462 682.437C767.981 681.092 767.773 679.666 767.85 678.24V659.81H775.64V676.62C775.513 678.201 776 679.769 777 681C777.518 681.525 778.144 681.932 778.835 682.191C779.525 682.45 780.264 682.555 781 682.5C781.767 682.535 782.533 682.4 783.241 682.104C783.95 681.808 784.585 681.36 785.1 680.79C786.164 679.516 786.706 677.887 786.62 676.23V659.81H794.39Z"
                  fill="black"
                />
                <path
                  id="Vector_9"
                  d="M810.07 688.86C808.436 688.917 806.812 688.583 805.332 687.888C803.852 687.192 802.559 686.154 801.56 684.86C799.473 682.193 798.427 678.587 798.42 674.04C798.413 669.493 799.467 665.91 801.58 663.29C802.592 662.003 803.894 660.974 805.38 660.287C806.866 659.601 808.494 659.276 810.13 659.34C812.015 659.301 813.87 659.812 815.47 660.81C816.968 661.731 818.147 663.088 818.85 664.7H819V649.9H826.78V688.4H819.1V683.5H819C818.284 685.122 817.082 686.481 815.56 687.39C813.907 688.393 812.003 688.903 810.07 688.86V688.86ZM812.71 665.6C811.809 665.572 810.916 665.766 810.108 666.165C809.301 666.565 808.604 667.157 808.08 667.89C806.868 669.73 806.276 671.91 806.39 674.11C806.27 676.308 806.863 678.486 808.08 680.32C808.627 681.028 809.328 681.601 810.131 681.995C810.933 682.389 811.816 682.594 812.71 682.594C813.604 682.594 814.487 682.389 815.289 681.995C816.092 681.601 816.793 681.028 817.34 680.32C818.469 678.449 819.066 676.305 819.066 674.12C819.066 671.935 818.469 669.791 817.34 667.92C816.819 667.182 816.124 666.583 815.316 666.179C814.509 665.774 813.613 665.575 812.71 665.6V665.6Z"
                  fill="black"
                />
                <path
                  id="Vector_10"
                  d="M839 655.49C838.198 656.247 837.137 656.668 836.035 656.668C834.933 656.668 833.872 656.247 833.07 655.49C832.689 655.117 832.386 654.673 832.18 654.181C831.973 653.69 831.867 653.163 831.867 652.63C831.867 652.097 831.973 651.57 832.18 651.079C832.386 650.587 832.689 650.143 833.07 649.77C833.877 649.024 834.936 648.61 836.035 648.61C837.134 648.61 838.193 649.024 839 649.77C839.381 650.143 839.684 650.587 839.89 651.079C840.097 651.57 840.203 652.097 840.203 652.63C840.203 653.163 840.097 653.69 839.89 654.181C839.684 654.673 839.381 655.117 839 655.49V655.49ZM832.14 688.4V659.81H839.93V688.4H832.14Z"
                  fill="black"
                />
                <path
                  id="Vector_11"
                  d="M868.56 685.06C866.02 687.727 862.57 689.06 858.21 689.06C853.85 689.06 850.403 687.727 847.87 685.06C845.323 682.393 844.05 678.727 844.05 674.06C844.05 669.46 845.34 665.82 847.91 663.17C849.254 661.823 850.864 660.771 852.638 660.082C854.411 659.393 856.309 659.083 858.21 659.17C862.53 659.17 865.973 660.503 868.54 663.17C871.1 665.82 872.38 669.46 872.38 674.06C872.38 678.66 871.1 682.42 868.56 685.06ZM858.21 683.06C859.108 683.084 859.997 682.882 860.796 682.471C861.595 682.06 862.277 681.454 862.78 680.71C863.876 678.683 864.45 676.414 864.45 674.11C864.45 671.806 863.876 669.537 862.78 667.51C862.264 666.778 861.58 666.18 860.784 665.767C859.989 665.355 859.106 665.139 858.21 665.139C857.314 665.139 856.431 665.355 855.636 665.767C854.84 666.18 854.156 666.778 853.64 667.51C852.535 669.534 851.955 671.804 851.955 674.11C851.955 676.416 852.535 678.686 853.64 680.71C854.141 681.457 854.822 682.065 855.622 682.478C856.421 682.89 857.311 683.094 858.21 683.07V683.06Z"
                  fill="black"
                />
                <path
                  id="Vector_12"
                  d="M875.9 668.21C875.868 666.934 876.15 665.67 876.722 664.53C877.294 663.389 878.138 662.407 879.18 661.67C881.36 660.02 884.29 659.2 887.95 659.2C891.023 659.049 894.058 659.932 896.57 661.71C897.612 662.478 898.457 663.482 899.036 664.64C899.614 665.797 899.911 667.076 899.9 668.37H892.73C892.705 667.84 892.564 667.321 892.317 666.851C892.07 666.381 891.723 665.971 891.3 665.65C890.298 665.034 889.15 664.696 887.974 664.671C886.798 664.646 885.637 664.936 884.61 665.51C884.232 665.742 883.92 666.069 883.706 666.458C883.492 666.848 883.383 667.286 883.39 667.73C883.39 669.12 884.64 670.11 887.12 670.73L892.59 671.91C894.86 672.274 896.992 673.233 898.77 674.69C899.409 675.35 899.906 676.135 900.229 676.995C900.552 677.855 900.696 678.772 900.65 679.69C900.675 681.024 900.366 682.343 899.752 683.527C899.137 684.711 898.236 685.723 897.13 686.47C894.414 688.28 891.191 689.177 887.93 689.03C884.163 689.03 881.163 688.193 878.93 686.52C877.845 685.746 876.949 684.737 876.309 683.568C875.668 682.399 875.299 681.101 875.23 679.77H882.83C882.896 680.328 883.079 680.865 883.367 681.348C883.654 681.83 884.041 682.247 884.5 682.57C885.593 683.286 886.885 683.636 888.19 683.57C889.403 683.636 890.61 683.355 891.67 682.76C892.06 682.534 892.383 682.208 892.606 681.816C892.829 681.425 892.944 680.981 892.94 680.53C892.951 680.196 892.884 679.865 892.746 679.561C892.607 679.257 892.4 678.99 892.14 678.78C891.317 678.226 890.387 677.852 889.41 677.68L884.13 676.5C878.65 675.247 875.907 672.483 875.9 668.21Z"
                  fill="black"
                />
              </g>
              <g id="R">
                <path
                  id="R_2"
                  d="M570.34 556.56V604H540.6V475.79H582.31C591.583 475.79 599.487 476.743 606.02 478.65C611.839 480.207 617.282 482.929 622.02 486.65C625.989 489.847 629.085 493.995 631.02 498.71C634.326 507.319 634.727 516.773 632.16 525.63C631.056 529.294 629.368 532.755 627.16 535.88C624.906 539.075 622.178 541.907 619.07 544.28C615.72 546.83 612.014 548.875 608.07 550.35C610.071 551.356 611.945 552.598 613.65 554.05C615.439 555.581 616.969 557.391 618.18 559.41L645.48 604H618.55C613.57 604 609.993 602.123 607.82 598.37L586.53 561.15C585.771 559.698 584.63 558.481 583.23 557.63C581.571 556.844 579.743 556.484 577.91 556.58L570.34 556.56ZM570.34 536.06H582.34C585.846 536.14 589.34 535.619 592.67 534.52C595.29 533.632 597.681 532.174 599.67 530.25C601.488 528.469 602.859 526.283 603.67 523.87C604.507 521.331 604.922 518.673 604.9 516C604.9 510.373 603.067 505.973 599.4 502.8C595.733 499.627 590.067 498.043 582.4 498.05H570.4L570.34 536.06Z"
                  fill="#A30000"
                />
              </g>
              <g id="E">
                <path
                  id="E_2"
                  d="M740.14 475.79V498.67H687.53V528.58H727.83V550.58H687.53V581.1H740.14V604H657.62V475.79H740.14Z"
                  fill="#A30000"
                />
              </g>
              <g id="D">
                <path
                  id="D_2"
                  d="M873.34 539.84C873.431 548.621 871.803 557.334 868.55 565.49C865.474 573.121 860.862 580.037 855 585.81C848.99 591.659 841.842 596.21 834 599.18C825.304 602.472 816.067 604.101 806.77 603.98H757V475.79H806.8C816.101 475.672 825.34 477.314 834.03 480.63C841.847 483.634 848.978 488.181 855 494C860.853 499.757 865.452 506.661 868.51 514.28C871.772 522.403 873.412 531.087 873.34 539.84V539.84ZM842.9 539.84C842.976 534.027 842.146 528.238 840.44 522.68C838.982 517.911 836.57 513.488 833.35 509.68C830.265 506.118 826.397 503.319 822.05 501.5C817.224 499.521 812.045 498.548 806.83 498.64H787V581.1H806.88C812.094 581.191 817.272 580.221 822.1 578.25C826.446 576.424 830.312 573.622 833.4 570.06C836.62 566.252 839.032 561.829 840.49 557.06C842.181 551.48 842.994 545.67 842.9 539.84Z"
                  fill="#A30000"
                />
              </g>
              <g id="tinta">
                <path
                  id="Vector_13"
                  d="M811.53 231C683.53 231 573.61 310.27 528 422.43H553.13C596.52 325.15 693 256.71 805.3 254.26V313.26L791.38 327.18C725.86 333.43 668.98 369.85 634.73 422.4H648.73C664.991 399.311 685.969 379.936 710.275 365.558C734.582 351.179 761.663 342.123 789.73 338.99L805.34 354.61V407.51C772.544 409.087 741.567 423.046 718.66 446.57L735.51 446.68C756.792 428.776 783.718 418.972 811.53 419C876.96 419 930.19 472.4 930.19 538C930.221 561.516 923.259 584.51 910.19 604.06H923.83C934.518 585.867 940.617 565.347 941.6 544.27H995.15L1010 559.06C999.47 659.58 914.5 738.18 811.56 738.18C745.93 738.18 687.56 706.18 651.21 657H637C675 712.94 739.07 749.75 811.52 749.75C920.82 749.75 1010.97 666.01 1021.58 559.13L1036.48 544.23H1094.38C1091.06 697.88 965.46 821.86 811.52 821.86C697.85 821.86 599.63 754.24 554.69 657H529.41C575.84 767.34 684.82 845 811.53 845C980.3 845 1117.6 707.28 1117.6 538C1117.6 368.72 980.3 231 811.53 231ZM1094.39 531.76H1036.49L1021.59 516.86C1011.59 416.63 931.73 336.77 831.69 327.22L817.77 313.3V254.22C968.84 257.51 1091.12 380.19 1094.39 531.77V531.76ZM817.77 407.55V354.65L833.39 339C926.28 349.21 1000.2 423.61 1009.97 516.92L995.14 531.75H941.59C938.44 464.71 884.62 410.73 817.77 407.55Z"
                  fill="black"
                />
              </g>
            </g>
          </svg>
        </Link>
      </div>
      <ul className={`nav-links ${burgerState ? "toggle" : ""}`}>
        <Link to="/">
          <li className="nav-link" onClick={() => setBurgerState(false)}>
            Acasă
          </li>
        </Link>
        <Link to="/desprenoi">
          <li className="nav-link" onClick={() => setBurgerState(false)}>
            Despre Noi
          </li>
        </Link>
        <Link to="/echipa">
          <li className="nav-link" onClick={() => setBurgerState(false)}>
            Echipă
          </li>
        </Link>
        <Link to="/proiecte">
          <li className="nav-link" onClick={() => setBurgerState(false)}>
            Proiecte
          </li>
        </Link>
        <Link to="/echipamente">
          <li className="nav-link" onClick={() => setBurgerState(false)}>
            Echipamente
          </li>
        </Link>
        <Link to="/media">
          <li className="nav-link" onClick={() => setBurgerState(false)}>
            Media
          </li>
        </Link>
        <a className="nav-contact" href="#bottomofpage">
          <li
            className="nav-link"
            onClick={() => {
              setBurgerState(false);
            }}
          >
            Contact
          </li>
        </a>
      </ul>
      <div
        className={`burger ${burgerState ? "toggle" : ""}`}
        onClick={toggleState}
      >
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </div>
  );
}
