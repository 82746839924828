import React, { useState, useEffect, useRef } from "react";
import "../css/App.css";
import "./css/Proiecte.css";

export default function Proiecte() {
  const [open, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");
  const [mobile, setMobile] = useState(false);

  const videoRef = useRef();

  const pauseVideo = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const Descrieri = [
    "Irina Rimes x Damian Draghici",
    'Sesiune live "Aripi de Vis" - Alina Eremia',
    'Solo chitară "Vin și Pastile" - Irina Rimes',
    "Sesiune live Bits & Pieces",
    "Sesiune live Natalia",
    "Sesiune live Bianca",
    "Sesiune live Muddy",
    "Sesiune live Betty",
    "Sesiune live Petra",
    "Sesiune live Sara",
    "Sesiune live Silvia",
  ];

  let index = 0,
    index2 = 0;

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setMobile(true);
    }
  }, []);

  const Open = () => {
    setOpen((prev) => !prev);
  };

  let req = require.context(".././components/img/Proiecte/", false, /.*\.png$/);
  let images = [];
  req.keys().forEach((key) => {
    images.push({ key: req(key), index: index, descriere: Descrieri[index] });
    index++;
  });

  let req2 = require.context(
    ".././components/img/Proiecte/",
    false,
    /.*\.(mov|m4v)$/
  );
  let videos = [];
  req2.keys().forEach((key) => {
    videos.push({
      key: req2(key),
      index: index2,
      descriere: Descrieri[index2],
    });
    index2++;
  });

  const playVideo = () => {
    if (open)
      return (
        <>
          <video
            controls
            autoPlay
            loop
            className={`project-video ${open ? "open" : ""}`}
            id="video"
            ref={videoRef}
            onPause={() => {
              if (mobile) Open();
            }}
            alt="Video reprezentativ Proiect"
          >
            <source src={currentVideo.key} />
            Your browser does not support the video tag.
          </video>
          <p className={`caption ${open ? "open" : ""}`}>
            {currentVideo.descriere}
          </p>
        </>
      );
  };

  const listItems = images.map((image) => (
    <div className="project-img-container">
      <img
        src={image.key}
        alt="Poza pentru Video Proiect"
        onClick={() => {
          Open();
          setCurrentVideo(videos[image.index]);
        }}
        key={image.index}
      />
      <p className="project-text">{image.descriere}</p>
    </div>
  ));

  return (
    <>
      <div className="grid-master">
        <h1>Proiecte</h1>
        <div className="projects-master">{listItems}</div>
      </div>
      <div
        className={`modal ${open ? "open" : ""}`}
        onClick={(e) => {
          if (e.target.classList.contains("modal")) {
            Open();
            pauseVideo();
          }
        }}
      >
        {playVideo()}
      </div>
    </>
  );
}
