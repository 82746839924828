import React, { useState } from "react";
import instagram from "./img/Icons/instagram.png";
import facebook from "./img/Icons/facebook.png";
import "./css/Footer.css";
import ContactForm from "./ContactForm";

export default function Footer() {
  const [open, setOpen] = useState(false);

  const isOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <footer className="main-footer">
        <div className="container main-footer-container">
          <h4 className="main-footer-description">CONTACT</h4>
        </div>
        <div className="sub-footer-container">
          <div className="sub-footer-text-container">
            <button
              className="join-btn-footer"
              onClick={() => {
                isOpen();
              }}
            >
              Rezervă acum
            </button>
            <p className="telefon">+40 723 694 684</p>
            <p className="email">contact@infraredstudios.ro</p>
            <p className="location">Strada Cuțitul de Argint, nr. 3</p>
            <p className="location">Arenele Romane, Parcul Carol I</p>
          </div>
          <div className="sub-footer-maps-container">
            <iframe
              width="1000"
              height="450"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAuFLF2u23I5OyQ6xxkPy9PNZ8ZI_WIcHk&q=Arenele+Romane&language=ro`}
              allowFullScreen
              title="map"
            ></iframe>
          </div>
        </div>
        <div id="bottomofpage" className="container main-footer-container">
          <h3 className="infrared">Infrared Studios</h3>
          <h4 className="copyright">
            ©2020 InfraRED Studios. Toate drepturile rezervate.
          </h4>
          <ul>
            <li>
              <a href="https://www.facebook.com/InfraREDstudios.ro">
                <img src={facebook} alt="Facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/infra.red.studios/">
                <img src={instagram} alt="Instagram" />
              </a>
            </li>
          </ul>
        </div>
      </footer>
      <div
        className={`modal ${open ? "open" : ""}`}
        onClick={(e) => {
          if (e.target.classList.contains("modal")) {
            isOpen();
          }
        }}
      >
        <ContactForm openThis={isOpen} />
      </div>
    </>
  );
}
