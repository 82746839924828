import React, { useState, useRef, useEffect } from "react";
import "./css/Media.css";

export default function Media() {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({ key: "", index: "" });
  const currentFocusedImage = useRef();

  let index = 0,
    index2 = 0;

  const Open = () => {
    setOpen((prev) => !prev);
  };

  let req = require.context(
    ".././components/img/Media Thumbnails",
    false,
    /.*\.jpg$/
  );
  let previews = [];
  req.keys().forEach((key) => {
    previews.push({ key: req(key), index: index });
    index++;
  });

  let req2 = require.context(".././components/img/Media", false, /.*\.jpg$/);
  let images = [];
  req2.keys().forEach((key) => {
    images.push({ key: req2(key), index: index2 });
    index2++;
  });

  const goToNextImage = () => {
    index = currentImage.index;
    if (images[index + 1] !== undefined) setCurrentImage(images[index + 1]);
    else setCurrentImage(images[0]);
  };

  const goToPrevImage = () => {
    index = currentImage.index;
    if (images[index - 1] !== undefined) setCurrentImage(images[index - 1]);
    else setCurrentImage(images[images.length - 1]);
  };

  useEffect(() => {
    if (document.activeElement !== currentFocusedImage.current)
      currentFocusedImage.current.focus();
  }, [currentImage]);

  const listItems = previews.map((preview) => (
    <div className="img-container">
      <img
        src={preview.key}
        alt="Preview Poza Studio Echipamente"
        onClick={() => {
          Open();
          setCurrentImage(images[preview.index]);
        }}
        key={preview.index}
      />
    </div>
  ));

  return (
    <>
      <div className="grid-master">
        <h1>Media</h1>
        <div className="grid-container">{listItems}</div>
      </div>
      <div
        className={`modal ${open ? "open" : ""}`}
        onClick={(e) => {
          if (e.target.classList.contains("modal")) {
            Open();
          }
        }}
      >
        <img
          src={currentImage.key}
          alt="Poza Studio pentru echipamente si inregistrari"
          className={`full-image ${open ? "open" : ""}`}
          onClick={goToNextImage}
          ref={currentFocusedImage}
          onKeyDown={(e) => {
            if (e.key === "ArrowRight") goToNextImage();
            if (e.key === "ArrowLeft") goToPrevImage();
            if (e.key === "Escape") Open();
          }}
          tabIndex={-1}
        />
        <p className={`caption ${open ? "open" : ""}`}>
          {currentImage.index + 1} / {images.length}
        </p>
        <div className="leftarrow" onClick={goToPrevImage}></div>
        <div className="rightarrow" onClick={goToNextImage}></div>
      </div>
    </>
  );
}
