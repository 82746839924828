import React from "react";
import "./css/Echipamente.css";

export default function Echipamente() {
  return (
    <div>
      <div className="echipamente-studio">
        <h1 className="echipamente-studio-title">Studio</h1>
        <div className="wrapper">
          <div
            className="echipamente-card-container microfoane"
            title="Microfoane"
          >
            <h2>Microfoane</h2>
          </div>
          <p>Neuman U87</p>
          <p>6x AKG C414</p>
          <p>6x Sennheiser MD421</p>
          <p>6x Shure KSM 141</p>
          <p>3x Audix D6</p>
          <p>2x Shure Beta 52</p>
          <p>2x Shure Beta 91</p>
          <p>8x Shure SM 57</p>
          <p>8x Shure SM 81</p>
          <p>10x Sennheiser 604</p>
          <p>6x Sennheiser 904</p>
          <p>2x Sennheiser E906</p>
          <p>4x Shure Wireless QLXD</p>
        </div>
        <div className="wrapper">
          <div
            className="echipamente-card-container statii"
            title="Amplificatoare"
          >
            <h2>Amplificatoare</h2>
          </div>
          <p>2x Fender Twin Reverb</p>
          <p>Marshall JCM 900</p>
          <p>Marshall JCM 2000</p>
          <p>Blackstar Series One 200</p>
          <p>Markbass Little Mark 800</p>
          <p>Markbass standard 104HF 4Ohm</p>
          <p>Hartke Bass Combo 4x12"</p>
          <p>2x Marshall 4x12" 1960 Straight Cabinet</p>
          <p>Marshall MR1936 V 2x12" Vintage Cabinet</p>
          <p>Vox AC10</p>
        </div>
        <div className="wrapper">
          <div
            className="echipamente-card-container procesoare"
            title="Procesoare Placi de Sunet"
          >
            <h2>Procesoare & Plăci de sunet</h2>
          </div>
          <p>Apogee Symphony mk 2</p>
          <p>2x Empirical Labs EL8X-S Distressor</p>
          <p>Avalon V5 Silver</p>
          <p>Avalon VT-737SP</p>
          <p>Universal Audio LA-610 MkII</p>
          <p>Universal Audio Apollo Twin</p>
          <p>Mackie Big Knob Studio+</p>
          <p>Super Time Line</p>
          <p>Intelliverb</p>
          <p>Roland SDE 330</p>
          <p>Zoom RFX-1000</p>
          <p>Peavey Gatekeeper</p>
        </div>
        <div className="wrapper">
          <div
            className="echipamente-card-container monitoare"
            title="Monitoare"
          >
            <h2>Monitoare</h2>
          </div>
          <p>Genelec 7360 APM</p>
          <p>2x Genelec 8050 BPM</p>
          <p>Adam A77X x2</p>
          <p>2x Turbosound 1x12"</p>
          <p>2x JBL VRX 915 M 1x15"x3"</p>
          <p>6x Beyer Dynamic DT-770 Pro Headphones</p>
          <p>8x In-ear Shure PSM 900 Beltpack, Receiver, Antenna</p>
        </div>
        <div className="wrapper">
          <div
            className="echipamente-card-container console"
            title="Console & Stagebox"
          >
            <h2>Console & Stagebox</h2>
          </div>
          <p>Soundcraft VI 7000 Console</p>
          <p>Local Rack, DSP Engine, 7x DSP Cards, 3x Madi Card</p>
          <p>Soundcraft Stagebox Rack 64 In / 32 Out</p>
          <p>Midas Venice 240</p>
          <p>2x Soundcraft SI IMPACT 32 ch</p>
          <p>Soundcraft VI CSB 16 RJ 45 Stagebox 32 In / 16 Out</p>
          <p>Soundcraft Stagebox Mini 32i 32 In / 12 Out</p>
          <p>2x Cabluri Multicore Digital Snake CAT5 75m</p>
          <p>Calbu Multicore Fibra Optica Neutrik NKOM2m-A-2 100m</p>
        </div>
        <div className="wrapper">
          <div
            className="echipamente-card-container daw-plugins"
            title="DAW Plugins"
          >
            <h2>DAW & Plugins</h2>
          </div>
          <p>Logic Pro X</p>
          <p>FL Studio</p>
          <p>Pro Tools</p>
          <p>Waves</p>
          <p>Slate Digital</p>
          <p>Native Instruments</p>
          <p>FabFilter</p>
          <p>iZotope</p>
          <p>Antares</p>
          <p>Plugin Alliance</p>
          <p>Celemony</p>
          <p>Sound Radix</p>
          <p>Valhalla</p>
          <p>Xfer Records</p>
          <p>Spectrasonics</p>
        </div>

        <div className="wrapper">
          <div
            className="echipamente-card-container tama"
            title="Tobe Drums Tama"
          >
            <h2>Tama Starclassic Bubinga</h2>
          </div>
          <p>22"x18" Bass Drum</p>
          <p>10"x8" Tom</p>
          <p>12"x9" Tom</p>
          <p>14"x12" Floor Tom</p>
          <p>16"x14" Floor Tom</p>
          <p>Tama HH905D Hi-Hat Iron Cobra Lever</p>
          <p>Tama HS800W Snare Stand</p>
          <p>4x Tama HC83W Cymbal Stand</p>
          <p>2x Tama MC69 Single Tom Attachment</p>
          <p>Tama HT530BC Drum Throne</p>
        </div>
        <div className="wrapper">
          <div
            className="echipamente-card-container yamaha"
            title="Tobe Drums Yamaha"
          >
            <h2>Yamaha Beach Custom</h2>
          </div>
          <p>22"x16" Bass Drum</p>
          <p>14"x6.5" Snare Drum</p>
          <p>10"x10" Tom</p>
          <p>12"x10" Tom</p>
          <p>14"x12" Tom</p>
          <p>15"x14" Tom</p>
          <p>Tama HH905D Hi-Hat Iron Cobra Lever</p>
          <p>2x Yamaha TH-904 Double Tom Holder</p>
          <p>4x Yamaha CL945B Single Tom Holder</p>
          <p>Tama HS800W Snare Stand</p>
          <p>Tama HT741B Drum Throne</p>
        </div>
        <div className="wrapper">
          <div
            className="echipamente-card-container instrumente"
            title="Instrumente Keys Guitars"
          >
            <h2>Keys & Guitars</h2>
          </div>
          <p>Roland RD-2000</p>
          <p>Roland RD 700 GX</p>
          <p>Yamaha MOTIF XF 8</p>
          <p>Nord Stage 3</p>
          <p>Sequential Prophet 12</p>
          <p>Gibson Les Paul Custom Anniversary 25/50 1979</p>
          <p>Gibson Les Paul Studio 1984</p>
          <p>Gibson Les Paul Special 2004</p>
          <p>Fender Telecaster Custom Shop</p>
          <p>Taylor 214ce CF DLX</p>
          <p>EKO NXT Nylon CW EQ</p>
          <p>Ibanez EWB 205</p>
        </div>
        <div className="wrapper others-wrapper">
          <div className="echipamente-card-container others" title="Altele">
            <h2>Altele</h2>
          </div>
          <p>iMac Pro 5K i9 64GB RAM 3TB</p>
          <p>MacBook Pro 2020 16-inch i9</p>
          <p>3x Furman P-1400 AR E</p>
          <p>Hofa Acoustic Treatment</p>
        </div>
      </div>
    </div>
  );
}
