import React from "react";
import { Link } from "react-router-dom";
import "./css/Error.css";

export default function Error() {
  return (
    <div className="pagina-eroare">
      <h1>404</h1>
      <h2>Pagina nu exista!</h2>
      <Link to="/">
        <button className="btn-eroare">Inapoi la pagina principala</button>
      </Link>
    </div>
  );
}
