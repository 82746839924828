import React, { useState, useEffect } from "react";
import "./css/Main.css";
import Recording from "./img/HomePage/Recording.jpg";
import Mixing from "./img/HomePage/Mixing.jpg";
import Production from "./img/HomePage/Production.jpg";
import ContactForm from "./ContactForm";

export default function Main() {
  const [open, setOpen] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 1024) setMobile(true);
  }, []);

  const Open = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <div className="title-container" title="Home Page Mixer">
        <div className="title-alpha">
          <div className="title-logo">
            <h1>
              Infra<div className="red">RED</div> Studios
            </h1>
            <h2>Sunetul muzicii tale</h2>
            <button
              className="join-btn"
              onClick={() => {
                Open();
              }}
            >
              Rezervă acum
            </button>
          </div>
        </div>
      </div>
      <div className="presentation-master">
        <div className="presentation-container">
          <div
            className="card-container"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-once="true"
          >
            <h1>Recording</h1>
            <img
              className="presentation-image"
              src={Recording}
              alt="Recording"
            />
            <p>
              Îmbinarea perfectă dintre digitalul de ultimă generație și
              analogicul legendar, plin de caracter
            </p>
          </div>
          <div
            className="card-container"
            data-aos={`${mobile ? "fade-down" : "fade-up"}`}
            data-aos-duration={`${mobile ? "1500" : "800"}`}
            data-aos-once="true"
          >
            <h1>Mixing</h1>
            <img className="presentation-image" src={Mixing} alt="Mixing" />
            <p>Butoanele potrivite. Mâinile potrivite. Urechile potrivite</p>
          </div>
          <div
            className="card-container"
            data-aos="fade-up"
            data-aos-duration={`${mobile ? "2200" : "800"}`}
            data-aos-once="true"
          >
            <h1>Production</h1>
            <img
              className="presentation-image"
              src={Production}
              alt="Production"
            />
            <p>
              Dansul dintre imaginația inifinită a unui muzician și talentul
              tehnic al unui sound designer
            </p>
          </div>
        </div>
      </div>
      <div
        className={`modal ${open ? "open" : ""}`}
        onClick={(e) => {
          if (e.target.classList.contains("modal")) {
            Open();
          }
        }}
      >
        <ContactForm openThis={Open} />
      </div>
    </>
  );
}
