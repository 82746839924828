import React, { useState } from "react";
import "./css/ContactForm.css";

export default function ContactForm({ openThis }) {
  const initialState = {
    nume: "",
    email: "",
    nrtel: "",
    mesaj: "",
  };

  const [optionState, setOptionState] = useState("Inregistrare solist");
  const optionsVector = [
    { value: "Inregistrare solist" },
    { value: "Inregistrare trupă" },
    { value: "Producție piesă" },
    { value: "Editare audio" },
    { value: "Mix & Master" },
  ];

  const [datePersonale, setDatePersonale] = useState(initialState);
  const [hidden, setHidden] = useState(false);

  const Hide = () => {
    setHidden((prev) => !prev);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    window.Email.send({
      SecureToken: "62274EC2-F6E2-47B4-BE31-463045C4DC85",
      To: "support@infraredstudios.ro",
      From: datePersonale.email,
      Subject: datePersonale.nume,
      Body: `Nume: ${datePersonale.nume}<br>
      Adresa de mail: ${datePersonale.email}<br>
      Mesaj: ${datePersonale.mesaj}<br>
      Numar de telefon: ${datePersonale.nrtel}<br>
      Optiune: ${optionState}`,
    });
  };

  return (
    <div className="form-popup" id="myForm">
      <form
        onSubmit={sendEmail}
        className={`form-container ${hidden ? "hidden" : ""}`}
      >
        <h1>Lasă-ne un mesaj!</h1>
        <label htmlFor="optiune">
          <b>Optiune</b>
        </label>
        <select
          className="optiune"
          value={optionState}
          onChange={(e) => {
            setOptionState(e.target.value);
          }}
        >
          {optionsVector.map((option) => (
            <option value={option.value}>{option.value}</option>
          ))}
        </select>
        <label htmlFor="nume">
          <b>Nume</b>
        </label>
        <input
          type="text"
          placeholder="Introduceți numele"
          name="nume"
          value={datePersonale.nume}
          required
          onChange={(e) => {
            setDatePersonale({ ...datePersonale, nume: e.target.value });
          }}
        />
        <label htmlFor="email">
          <b>Email</b>
        </label>
        <input
          type="email"
          placeholder="Introduceți adresa de email"
          name="email"
          value={datePersonale.email}
          required
          onChange={(e) => {
            setDatePersonale({ ...datePersonale, email: e.target.value });
          }}
        />
        <label htmlFor="nrtel">
          <b>Număr de Telefon</b>
        </label>
        <input
          type="number"
          placeholder="Introduceți numărul de telefon"
          name="nrtel"
          value={datePersonale.nrtel}
          required
          onChange={(e) => {
            setDatePersonale({ ...datePersonale, nrtel: e.target.value });
          }}
        />
        <label htmlFor="mesaj">
          <b>Mesaj</b>
        </label>
        <textarea
          type="text"
          placeholder="Mesajul dvs."
          name="mesaj"
          rows="2"
          value={datePersonale.mesaj}
          required
          onChange={(e) => {
            setDatePersonale({ ...datePersonale, mesaj: e.target.value });
          }}
        />
        <button
          type="submit"
          className={`btn special-button ${hidden ? "hidden" : ""}`}
          onClick={(e) => {
            if (datePersonale.optiune !== "") {
              let character = false;
              if (
                Object.values(datePersonale).forEach((key) => {
                  if (key !== "") character = true;
                  else character = false;
                }) !== ""
              )
                if (character) {
                  Hide();
                  setTimeout(openThis, 2000);
                }
            } else {
              e.stopPropagation();
            }
          }}
        >
          Trimite
        </button>
        <h2 className={`special-text ${hidden ? "hidden" : ""}`}>
          Vă așteptăm la Infra
          <div style={{ color: "#a30000", display: "inline" }}>RED</div>!
        </h2>
      </form>
    </div>
  );
}
